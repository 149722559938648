// Test colors
$color-button-text-secondary-default: #75748B;

// Surfaces
$color-bg-card-xxx-default: #F2F1F9;
$surface-color-bg-chips-tag-error: #F8E1E1;

// Borders
$border-neutral-minimal: #E9E6F4;


:root {
  // Text colors
  --text-color-button-text-secondary-default: #75748B;

  // Surface colors
  --surface-color-bg-card-xxx-default: #F2F1F9;

  // Spacing
  --Spacing-sm: 8px;
  --Spacing-lg: 16px;
  --Spacing-2xl: 24px;

}
