@mixin margin-spacing($direction, $size) {
  @if $direction == "l" {
    .spacing-#{$direction}#{$size} {
      margin-left: #{$size}px;
    }

    .padding-#{$direction}#{$size} {
      padding-left: #{$size}px;
    }

  } @else if $direction == "r" {
    .spacing-#{$direction}#{$size} {
      margin-right: #{$size}px;
    }

    .padding-#{$direction}#{$size} {
      padding-right: #{$size}px;
    }
  } @else if $direction == "t" {
    .spacing-#{$direction}#{$size} {
      margin-top: #{$size}px;
    }

    .padding-#{$direction}#{$size} {
      padding-top: #{$size}px;
    }
  } @else if $direction == "b" {
    .spacing-#{$direction}#{$size} {
      margin-bottom: #{$size}px;
    }

    .padding-#{$direction}#{$size} {
      padding-bottom: #{$size}px;
    }
  } @else if $direction == "h" {
    .spacing-#{$direction}#{$size} {
      margin-left: #{$size}px;
      margin-right: #{$size}px;
    }

    .padding-#{$direction}#{$size} {
      padding-left: #{$size}px;
      padding-right: #{$size}px;
    }
  } @else if $direction == "v" {
    .spacing-#{$direction}#{$size} {
      margin-top: #{$size}px;
      margin-bottom: #{$size}px;
    }

    .padding-#{$direction}#{$size} {
      padding-top: #{$size}px;
      padding-bottom: #{$size}px;
    }
  }
}
