.new {
  // background-color: var(--hui-light-neutral-2);
  color: var(--hui-light-neutral-9);
}

.completed, .dla-is-reviewing, .vendor-is-responding {
  // background-color: var(--hui-light-primary-3);
  color: var(--hui-veolia-classic-ocean);
}

.need-help, .on-hold, .reservations {
  // background-color: #FBF6DB !important;
  color: #F08F75 !important;
}

.cancelled, .not-recommended, .pending {
  // background-color: #F8E1E1 !important;
  color: #EA6868 !important;
}

.recommended {
  // background-color: var(--hui-light-functional-success--light);
  color: var(--hui-dark-functional-success);
}
