.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media (max-width: 767.98px) {
      padding-right: 10px;
      padding-left: 10px;
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
      padding-right: 20px;
      padding-left: 20px;
  }

  @media (min-width: 1200px) {
      padding-right: 50px;
      padding-left: 50px;
  }
}
